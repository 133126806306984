import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel,
} from "victory";
import moment from "moment";
import styles from "../../../therapy-tests/index.module.scss";

const columns = [
  { id: "date", label: "Дата/час проходження тесту" },
  { id: "point", label: "Оцінка" },
];

const PassedTestModal = ({ isOpen, test, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const chartData = useMemo(
    () =>
      test?.passes.map((item, i) => {
        const dateArr = item.date.split(".");
        return {
          point: item.sum,
          date: moment(
            dateArr[2] + "-" + dateArr[1] + "-" + (dateArr[0] - i),
            "YYYY-MM-DD"
          ).toDate(),
        };
      }),
    [test]
  );

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "date":
        return moment(new Date(value)).locale("uk").format("LL");
      default:
        return value;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    test &&
    chartData && (
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle className='!font-bold'>
          {`Дані проходження користувачем тесту - "${test?.testName}"`}
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label='test data tabs'
          >
            <Tab label='Графік' />
            <Tab label='Інформація про тест' />
          </Tabs>
          <Box hidden={tabIndex !== 0}>
            <VictoryChart
              domain={{
                y: [
                  0,
                  Math.max(
                    chartData?.reduce(
                      (max, obj) => (obj.point > max ? obj.point : max),
                      -Infinity
                    )
                  ) + 4,
                ],
              }}
              padding={{ top: 20, bottom: 80, left: 30, right: 20 }}
            >
              <VictoryAxis
                dependentAxis
                style={{
                  grid: { stroke: "rgba(0, 0, 0, 0.1)" },
                  axis: { stroke: "red" },
                  tickLabels: { fontSize: 10 },
                }}
              />
              <VictoryAxis
                style={{
                  axis: { stroke: "blue" },
                }}
                tickLabelComponent={
                  <VictoryLabel
                    angle={90}
                    textAnchor='start'
                    verticalAnchor='middle'
                    style={{ fontSize: 10 }}
                  />
                }
                labelPlacement='perpendicular'
              />
              {chartData.length === 1 ? (
                <VictoryScatter
                  data={chartData.map((item) => ({
                    x: moment(item.date).format("DD/MM/YY"),
                    y: item.point,
                  }))}
                  size={5}
                  style={{
                    data: { fill: "#8884d8" },
                    labels: { fontSize: 10 },
                  }}
                  labels={({ datum }) => datum.y}
                />
              ) : (
                <VictoryLine
                  interpolation='natural'
                  data={chartData.map((item) => ({
                    x: moment(item.date).format("DD/MM/YY"),
                    y: item.point,
                  }))}
                  style={{
                    data: { stroke: "#8884d8" },
                  }}
                  labels={({ datum }) => datum.y}
                  labelComponent={
                    <VictoryLabel
                      dx={10}
                      textAnchor='start'
                      verticalAnchor='middle'
                      style={[{ fill: "green", fontSize: 10, fontWeight: 700 }]}
                    />
                  }
                />
              )}
            </VictoryChart>
          </Box>
          <Box hidden={tabIndex !== 1}>
            <TableContainer
              sx={{ width: "100%", position: "relative", marginBottom: "20px" }}
            >
              <Table
                stickyHeader
                aria-labelledby='tableTitle'
                size={"small"}
              >
                <TableHead>
                  <TableRow>
                    {chartData.length > 0 &&
                      columns.map((item) => (
                        <TableCell
                          key={item.id}
                          align='center'
                          style={{ width: "50%", fontWeight: "bold" }}
                        >
                          {item.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chartData?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index.toString()}
                      >
                        {columns?.map((column) => {
                          return (
                            <TableCell
                              key={column.id}
                              align='center'
                              className={styles.rowItemContainer}
                            >
                              {handleCellView(row[column.id], column.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    )
  );
};

export default PassedTestModal;
