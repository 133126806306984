import React, { useEffect, useState } from "react";
import {
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import InviteClientDialog from "../invite-client-dialog";
import limbo from "../../../../services/core/limboclient";
import styles from "../../../therapy-tests/index.module.scss";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";

const columns = [
  { id: "email", label: "Запрошені клієнти" },
  { id: "id", label: "Посилання на авторизацію для клієнтів" },
];

const InvitationsView = () => {
  const [invites, setInvites] = useState([]);
  const [isOpenInviteClientDialog, setIsOpenInviteClientDialog] =
    useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });

  useEffect(() => {
    if (!isOpenInviteClientDialog) {
      limbo
        .call("=>therapist_getInvites~")
        .then((result) => setInvites(result));
    }
  }, [isOpenInviteClientDialog]);

  const handleCopyPaymentLink = (url) => {
    navigator.clipboard.writeText(url);
    setOpenSnackbar({
      isOpen: true,
      alertType: "success",
      message: "Посилання-запрошення було успішно скопійоване!",
    });
  };

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "id":
        return (
          <div className='flex items-center'>
            <p>
              {window.location.origin}/r/sign-in/{value}
            </p>
            <ContentCopyTwoToneIcon
              className='ml-3 cursor-pointer'
              onClick={(event) => {
                event.stopPropagation();
                handleCopyPaymentLink(
                  `${window.location.origin}/r/sign-in/${value}`
                );
              }}
              color='warning'
            />
          </div>
        );
      default:
        return value;
    }
  };

  return (
    <div>
      <TableContainer
        sx={{
          width: "100%",
          position: "relative",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <Table
          stickyHeader
          aria-labelledby='tableTitle'
          size='small'
        >
          <TableHead>
            <TableRow>
              {invites.length > 0 &&
                columns.map((item) => (
                  <TableCell
                    key={item.id}
                    align='left'
                    style={{ fontWeight: "bold" }}
                  >
                    {item.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invites?.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index.toString()}
                >
                  {columns?.map((column) => {
                    return (
                      <TableCell
                        key={column.id}
                        align='left'
                        className={styles.rowItemContainer}
                      >
                        {handleCellView(row[column.id], column.id)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant='contained'
        color='warning'
        className='!mt-5'
        onClick={() => setIsOpenInviteClientDialog(true)}
      >
        Надіслати запрошення клієнту
      </Button>
      <InviteClientDialog
        isOpen={isOpenInviteClientDialog}
        onClose={() => setIsOpenInviteClientDialog(false)}
      />
      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          severity={openSnackbar.alertType}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default InvitationsView;
